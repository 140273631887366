#trade-table *{
    font-family: 'Futura-pt', sans-serif;
}

#trade-table{
    margin: 2rem 0;
}

#trade-table th{
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.trade-table-cell{
    text-align: left;
    padding: 20px 30px;
}

#trade-tab-container{
    display: flex;
    overflow: scroll;
}

#trade-tab-container:hover{
    cursor: pointer;
}

.trade-tab{
    margin-right: 30px;
    font-family: 'Futura-pt', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
}

.trade-tab-active{
    margin-right: 30px;
    font-family: 'Futura-pt', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: bold;
}

.tab-underline{
    height: 3px;
    width: 100%;
    background-color: red;
}