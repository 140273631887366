.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 50vh;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left-color: #fb4622;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }