.badge {
    height: 25px;
    width: 25px;
    background-color: #fb4622;
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
  }