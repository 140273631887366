#customer-table *{
    font-family: 'Futura-pt', sans-serif;
}

#customer-table{
    margin: 2rem 0;
}

#customer-table th{
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.customer-table-cell{
    text-align: left;
    padding: 20px 30px;
}