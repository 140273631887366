*{
  box-sizing: border-box;
  color: #313131;
}

.primary-btn{
  padding: 10px 20px;
  background-color: #fb4622;
  border: unset;
  font-family: 'Futura-pt', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  border-radius: 5px;
}

.hover{
  cursor: pointer;
}

.business-outlet h1{
  font-family: 'Futura-pt';
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 2px;
}