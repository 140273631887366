#staff-table *{
    font-family: 'Futura-pt', sans-serif;
}

#staff-table{
    margin: 2rem 0;
}

#staff-table th{
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.staff-table-cell{
    text-align: left;
    padding: 20px 30px;
}