.business-nav {
    position: relative;
    height: 100vh;
    background-color: #fff;
    width: 256px;
    padding: 3rem 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: 0.5s ease-in-out;
    z-index: 998;
    border-right: 1px solid #f1f1f1;
  }

.business-nav-closed{
    position: relative;
    height: 100vh;
    width: 0;
    background-color: #fff;
    padding: 3rem 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: width 0.5s ease-in-out;
    z-index: 999;
    border-right: 1px solid #f1f1f1;
    transition:  0.5s ease-in-out;
    overflow: hidden;

}
.business-nav::before {
    content: "";
    position: absolute;
    width: 2rem;
    height: 100vh;
    top: 0;
    left: 100%;
}

#nav-welcome{
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    justify-content: center;
}

#nav-welcome > p{
    font-size: 0.8rem;
}

#business-avatar{
    height: 50px;
    width: 50px;
    border-radius: 15px;
    background-color: rgb(187, 187, 187);
    box-shadow: rgba(0, 0, 0, 0.65) 0px 5px 15px;}

.business-link-txt{
    font-size: 14px;
    font-family: 'Futura-pt', sans-serif;
    color: #313131;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.business-link-txt-active{
    font-size: 14px;
    font-family: 'Futura-pt', sans-serif;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;

}

#business-logout-btn{
    border: unset;
    background: unset;
}

.business-nav-item{
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 10px 0;
    border-radius: 15px;
    transition: 0.5s;
    text-decoration: none;

}

.business-nav-item:hover{
    background-color: rgba(252,224,224,0.32);
    border-radius: 15px;
    transition: 0.5s;
}

.business-nav-item-active{
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 10px 0;
    background-color: #fb4622;
    transition: 0.5s;
    text-decoration: none;
    border-radius: 15px;

}

.business-outlet{
    width: calc(100vw - 256px);
    height: 100vh;
    overflow: scroll;
    position: absolute;
    top: 0;
    left: 256px;
    transition: ease-in-out 0.5s;
    padding: 2rem;

}

.business-outlet-expanded{
    width: 100vw;
    height: 100vh;
    background-color: #fffdfa;
    overflow: scroll;
    position: absolute;
    top: 0;
    left: 0;
    transition: ease-in-out 0.5s;
}

.nav-icon{
    height: 30px;
    width: 30px;
    object-fit: contain;
    margin-right: 15px;
    opacity: 1;
}
.icon-filter{
    filter: brightness(0) invert(1);
}

#burger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
  }
  
  #burger.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  #burger.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  #burger.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 2px 0;
    transition: 0.4s;
  }

@media only screen and (max-width: 750px) {
    .business-nav {
        padding-bottom: 120px;  /*Prevents mobile bottom section covering settings icon*/
        max-width: 100vw;
    }
    .business-outlet{
        width: 100vw;
        height: 100vh;
        left: 0;
    
    }

    #burger {
        display: flex;
      }

  
}

